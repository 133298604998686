/* Header */
.docs-header {
  padding: 25px;
  font-size: 20px;
  color: #fff;
  background-color: #1ca8dd;
  background-image: linear-gradient(to bottom, #56b8b8, #1ca8dd);
}

.docs-header h1 {
  margin-bottom: 5px;
  font-size: 40px;
  line-height: 1;
}

.docs-header p {
  font-weight: 300;
  line-height: 1.4;
  color: #9fdcf3;
  margin-bottom: 0;
}

/* Content */
.docs-content {
  position: relative;
  padding: 0 25px 50px 25px;
  width: 100%;

  word-wrap: break-word;
  font-size: 16px;
}

.docs-content > h1 {
  padding-bottom: 9.5px;
  margin: 42px 0 21px;
  border-bottom: 1px solid #434857;
}
.docs-content > h1 + p {
  margin-bottom: 21px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

.docs-content h3 {
  font-weight: 400;
}

.docs-content h4 {
  font-style: italic;
}

.docs-links {
  display: none;
}

@media (min-width: 768px) {
  .docs-header {
    padding: 70px 30px;
    margin-bottom: 0;

    font-size: 24px;
  }

  .docs-header h1 {
    font-size: 60px;
  }

  .docs-content > h1 + p {
    font-size: 21px;
  }
}

@media (min-width: 1200px) {
  .docs-header p {
    max-width: 75%;
  }

  .docs-content {
    width: 80%;
  }

  .docs-links {
    width: 20%;
    display: block;
  }
}

/* Markdown + Other Details */
.markdown-toc,
.markdown-toc-fixed {
  padding-left: 0;
  margin-top: 30px;
  font-size: 13px;
  list-style: none;
}

.markdown-toc a,
.markdown-toc-fixed a {
  display: block;
  padding: 2px 10px 2px 10px;
}
.markdown-toc a:hover,
.markdown-toc-fixed a:hover {
  text-decoration: none;
  cursor: pointer;
  color: #56b8b8;
}
.markdown-toc > li,
.markdown-toc-fixed > li {
  margin-bottom: 5px;
}

.markdown-toc ul,
.markdown-toc-fixed ul {
  list-style: none;
  padding-left: 20px;
}
.markdown-toc ul ul,
.markdown-toc-fixed ul ul {
  display: none;
}
.scroll-top {
  position: fixed;
  right: 190px;
  bottom: 20px;
  display: none;
  padding: 6px 12px;
  font-size: 13px;
  border-radius: 4px;
  background-color: #1ca8dd;
  color: white;
  cursor: pointer;
  opacity: 1;
  -webkit-animation: fadein 1s linear none;
  animation: fadein 1s linear none;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scroll-top:hover {
  opacity: 0.37;
}

@media (min-width: 768px) {
  .scroll-top,
  .scroll-top-hidden {
    display: block;
  }

  .scroll-top {
    width: 220px;
    margin-right: -250px;
  }

  .markdown-toc-fixed {
    position: fixed;
    top: 20px;
  }
  .markdown-toc a,
  .markdown-toc-fixed a {
    color: #6f7890;
    padding-left: 20px;
  }
  .markdown-toc ul,
  .markdown-toc-fixed ul {
    display: none;
  }
  .markdown-toc > .active > ul,
  .markdown-toc-fixed > .active > ul {
    display: block;
  }
  .markdown-toc .active > a,
  .markdown-toc-fixed .active > a {
    padding-left: 18px;
    color: 0.1ca8dd;
    background-color: transparent;
    border-left: 2px solid #1ca8dd;
  }
  .markdown-toc.affix,
  .markdown-toc-fixed.affix {
    top: 0;
  }
}

.hidden {
  display: none;
}

.icon-up {
  margin-left: 5px;
  padding: 10px 20px;
  bottom: 80px;
}

.reference {
  font-size: 14px;
}
