.actions-container {
  position: relative;
}

.actions-panel {
  position: absolute;
  left: -250%;
  top: -5px;
  z-index: 100;
  width: 175px;
  -webkit-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.4);
}

.actions-panel-heading {
  padding: 5px;
}

.actions-panel-list-group {
  cursor: pointer;
  font-size: 12px;
}

.actions-panel-list-group li:hover {
  background: #1997c6;
  color: white;
  cursor: pointer;
}

.actions-panel-link {
  color: black;
}

.actions-panel-link:hover {
  text-decoration: none;
}

.arrow-right {
  position: absolute;
  width: 0;
  height: 0;

  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;

  border-left: 6px solid #1ca8dd;
  top: 11px;
  right: -6px;
}

@media screen and (min-width: 992px) {
  .actions-panel {
    left: -130%;
  }
}

.action-button {
  position: absolute;
}

.tooltip {
  display: none;
  top: -35px;
  left: -30px;
  position: absolute;
  background: black;
  color: white;
  border: none;
  padding: 3px 10px;
  border-radius: 4px;
}

.action-button:hover .tooltip {
  display: block;
}

.arrow-down {
  position: absolute;
  width: 0;
  height: 0;
  top: 24px;
  left: 40px;

  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid black;
}

/* Action Menu */
.download-link-container {
  width: 100%;
  height: 100%;
  color: #555;
  margin: 10px;
}

.download-link-container:hover {
  color: white;
}

.download-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: inherit;
  z-index: 3;
  padding: 10px;
}
