.thumbnailContainer {
  position: relative;
  cursor: pointer;
}

.thumbnailContainer:hover .thumbnail {
  -webkit-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.75);
}

.thumbnail {
  border: 1px #cecece solid;
  margin-bottom: 10px;
}

.thumbnailTitle {
  background: #222;
  color: white;
  padding: 3px 0;
  position: absolute;
  width: 100%;
  top: 60px;
  font-size: 12px;
  opacity: 0.7;
}
