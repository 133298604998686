.video-modal-container {
  cursor: pointer;
}

.video-modal-container span {
  color: #383838;
}

.video-modal-container:hover span {
  color: #1997c6 !important;
}
