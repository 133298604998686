.button-group button {
  width: 100%;
  border-radius: 0;
  font-family: 'Lato';
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
  font-weight: 300;
}

/* Button to enlarge chart */
.zoom,
.button-container,
.chart-header {
  font-family: 'Lato', sans-serif;
  cursor: pointer;
  width: 100%;
}

.zoom,
.button-container button {
  font-size: 11px;
  letter-spacing: 2px;
}

.zoom {
  border: 1px #ccc solid;
  color: #656c72;
}

.zoom:hover {
  background: #ccc;
  color: black;
}

.button-container button {
  color: white;
  background: #56b8b8;
  border: none;
  padding: 8px 14px;
  margin-top: 20px;
}

.button-container button:hover {
  opacity: 0.7;
}

.chart-header {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
  font-size: 36px;
}
