.instructions {
  margin-top: 30px;
  width: 100%;
}

.instruction-card {
  width: 100%;
  border: 1px #ccc solid;
  border-radius: 0;
}

.instruction-card h5 {
  width: 100%;
  padding: 10px;
  border-bottom: 1px #ccc solid;
}

.user-button-group {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.user-button-group button {
  width: 135px;
}

.remove {
  border: 1px red solid !important;
  color: red;
}

.remove:hover {
  background: red !important;
  color: white;
}
