.wellness-header div {
  padding: 10px 20px;
  border-bottom: 1px #ddd solid;
}

.wellness-header div.true {
  border-bottom: none;
  border-top: 1px #449ebf solid;
  letter-spacing: 2px;
  color: #449ebf;
  font-weight: 400;
  /* text-shadow: 0 1px 2px rgba(0, 0, 0, 0.20); */
  /* background: #449ebf; */
}

.wellness-header div.false {
  cursor: pointer;
  color: #888;
  background: #eee;
}

.wellness-header div:not(:last-child) {
  border-right: 1px #ddd solid;
}

.card-container {
  /* display: flex; */
  padding-top: 35px !important;
}

/* Metric Selectors */
.metric-selector-container {
  margin: 5px;
  cursor: pointer;
}

.metric-selector {
  /* width: 25%; */
  color: #585858;
  border: 1px #ddd solid;
  border-radius: 3px;
  opacity: 0.7;
}

.metric-selector.selected {
  color: #fff;
  border: none;
  background-color: #1686b0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.custom-statcard-desc {
  font-size: 80%;
}

.metric-selector .value {
  font-size: 30px;
}

.metric-selector .units {
  font-size: 22px;
}

@media screen and (min-width: 768px) {
  .metric-selectors {
    display: flex;
  }

  .metric-selector-container {
    width: 25%;
  }
}

@media screen and (min-width: 1200px) {
  .metric-selectors {
    display: block;
  }

  .metric-selector-container {
    width: 100%;
  }

  .custom-statcard-desc {
    font-size: 85%;
  }

  .metric-selector .value {
    font-size: 48px;
  }

  .metric-selector .units {
    font-size: 28px;
  }
}
