.gradient {
  height: 5px;
  background: linear-gradient(to right, white, blue);
  margin-bottom: 10px;
  width: 100%;
}

.gradientLegend {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;

  width: 100%;
}

.squaresContainer {
  /* display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;

  -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  position: relative;
  width: 45%;
}

.square {
  width: 100%;
  height: 0px;
  padding-bottom: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  border: 0.08em #888 solid;

  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
}

.leftFoot,
.rightFoot {
  position: absolute;
  margin: 0 auto;
  top: 25%;
  width: 80%;
}

.leftFoot {
  right: 0;
  padding-right: 50px;
  left: 55px;
}

.rightFoot {
  left: -28px;
  padding-left: 50px;
}

.topLeft,
.bottomLeft {
  padding-left: 10px;
}

.bottomLeft,
.bottomRight {
  padding-bottom: 5px;
}

.topRight,
.bottomRight {
  margin-left: auto;
  padding-right: 10px;
}

.topLeft,
.topRight {
  padding-top: 5px;
}
