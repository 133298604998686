.facility-dashboard-header {
  padding: 20px;
  color: #585858;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  letter-spacing: 2px;
  background: #f4f7fa;
}

.facility-list-group li:first-of-type {
  font-weight: 400;
}
