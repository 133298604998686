.demo-image-cell {
  width: 250px;
}

.demoImageContainer {
  width: 100%;
}

.demoImage {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 1200px) {
  .demo-image-cell {
    width: 500px;
  }

  .demoImageContainer {
    width: 350px;
  }

  .demoImage {
    height: 525px;
  }
}

.mapToolTips {
  height: 525px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mapToolTips div {
  color: black;
  cursor: pointer;
  position: absolute;
  white-space: nowrap;
}

.mapToolTips div div {
  display: none;
}

.neck {
  top: 95px;
  left: 155px;
  width: 35px;
  height: 20px;
}

.bust {
  top: 155px;
  left: 128px;
  width: 90px;
  height: 20px;
}

.chest {
  top: 155px;
  left: 128px;
  width: 90px;
  height: 20px;
}

.waist {
  top: 205px;
  left: 125px;
  width: 95px;
  height: 25px;
}

.hips {
  top: 260px;
  left: 120px;
  width: 110px;
  height: 25px;
}

.leftBiceps {
  top: 152px;
  left: 215px;
  width: 30px;
  height: 25px;
}

.rightBiceps {
  top: 155px;
  left: 100px;
  width: 30px;
  height: 25px;
}

.leftForearm {
  top: 215px;
  left: 245px;
  height: 25px;
  width: 30px;
}

.rightForearm {
  top: 210px;
  left: 75px;
  width: 30px;
  height: 25px;
}

.leftThigh {
  top: 292px;
  left: 175px;
  width: 50px;
  height: 25px;
}

.rightThigh {
  top: 292px;
  left: 122px;
  width: 50px;
  height: 25px;
}

.leftCalf {
  top: 385px;
  left: 190px;
  width: 40px;
  height: 25px;
}

.rightCalf {
  top: 388px;
  left: 120px;
  width: 40px;
  height: 25px;
}

.neck:hover .neckToolTip,
.bust:hover .bustToolTip,
.chest:hover .chestToolTip,
.chest:hover .chestToolTip,
.neck:hover .neckToolTip,
.waist:hover .waistToolTip,
.hips:hover .hipsToolTip,
.leftBiceps:hover .leftBicepsToolTip,
.rightBiceps:hover .rightBicepsToolTip,
.leftForearm:hover .leftForearmToolTip,
.rightForearm:hover .rightForearmToolTip,
.leftThigh:hover .leftThighToolTip,
.rightThigh:hover .rightThighToolTip,
.leftCalf:hover .leftCalfToolTip,
.rightCalf:hover .rightCalfToolTip {
  display: block;
  top: -35px;
  background-color: black;
  color: white;
  border: none;
  padding: 3px 10px;
  border-radius: 4px;
}
