.dashhead-toolbar {
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
}

.scan-date-select {
  border-bottomr-3ight-radius: 0;
  border-top-right-radius: 0;
  border-right: none;
}

.print-report {
}

@media screen and (min-width: 768px) {
  .client-report {
    width: calc(100% - 240px);
  }
}

@media print {
  .client-report {
    width: 100% !important;
  }
}
