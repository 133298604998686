.scanDateContainer {
  background-color: #eee;
  border: none;
  height: 30px;
  margin: 0 auto;
  width: 100%;
  margin-top: 10px;
}

.report-date-container {
  border: 1px #ddd solid;
  margin-right: 10px;
  margin-left: 10px;
}

.scanDateContainer,
.report-date-container {
  margin: 0 auto;
  width: 100%;
  border-radius: 2px;
}

.scanImageContainer {
  width: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.scanImageContainer video {
  width: 100%;
}

.scanImageContainer img {
  width: 100%;
}
