.survey {
  margin-bottom: 50px;
}

.survey-container {
  width: 100%;
  background: #fafafa;
}

.survey-progress {
  width: 100%;
  height: 30px;
  background: #f5f5f5;
  overflow: hidden;
}

.survey-progress-bar {
  height: 30px;
  width: 100%;
  background: linear-gradient(to left, #4cb8c4, #3cd3ad);
  transition: width 0.5s ease-in-out;
}

.header {
  font-size: 18px;
  color: #4cb8c4;
  font-weight: 700;
  letter-spacing: 2px;
}

/*.checkboxGroupContainer {
  display: inline-block;
}

.checkboxContainer {
  display: flex;
  padding: 0;
  align-items: center;
  width: 300px;
}

.checkboxContainer label {
  line-height: 25px;
  margin-left: 15px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 5px;
}*/

.radioHolder {
  width: 30%;
  background: #fff;
  margin-bottom: 1em;
  font-size: 1.1em;
  font-weight: 400;
  height: 2.4em;
  color: #666;
  -o-transition: 0.1s ease-out;
  -ms-transition: 0.1s ease-out;
  -moz-transition: 0.1s ease-out;
  -webkit-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
  cursor: pointer;
}

.radioHolder input[type='radio'],
.ratingsHolder input[type='radio'],
.radioHolder input[type='checkbox'],
.ratingsHolder input[type='checkbox'] {
  display: none;
}

.radioHolder .tick {
  display: inline-block;
  vertical-align: middle;
  width: 2.4em;
  height: 100%;
  background-color: #eee;
  background-image: none;
  -o-transition: 0.1s ease-out;
  -ms-transition: 0.1s ease-out;
  -moz-transition: 0.1s ease-out;
  -webkit-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
}

.activeRadioHolder .tick {
  background-color: #159aa8;
  background-image: url('http://supereightstudio.com/img/radio_tick.png');
  background-size: cover;
}

.radioHolder:hover {
  background-color: #eee;
}

.radioHolder:hover .tick {
  background-color: #ddd;
}

.activeRadioHolder:hover {
  background-color: #4cb8c4;
}

.activeRadioHolder:hover .tick {
  background-color: #159aa8;
}

.ratingsContainer {
  display: flex;
  justify-content: center;
}

.ratingsContainer .ratingsText {
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
}

.ratingscontainer .ratingsText:first-of-type {
  color: rgb(164, 25, 25);
}

.ratingsHolder {
  border: 1px #4cb8c4 solid;
  border-left: 0;
  height: 50px;
  width: 50px;
  color: #4cb8c4;
  font-weight: 400;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.1em;
  padding-top: 13px;
}

.ratingsHolder:first-of-type {
  border-left: 1px #4cbbc4 solid;
}

.ratingsHolder:hover {
  background: #4cb8c4;
  color: #fff;
}

.activeRadioHolder,
.activeRatingsHolder {
  background: #4db8c4;
  color: #fff;
}

.ratingsHolder span {
  margin: 0 auto;
}

.button {
  width: 200px;
  /*height: 40px;*/
}

.button:first-of-type {
  border: none !important;
  background: #159aa8;
}

.button:last-of-type {
  border-color: #159aa8;
  color: #159aa8;
}

.button:last-of-type:hover,
.button:last-of-type:active,
.button:last-of-type:focus {
  color: #fff;
}
