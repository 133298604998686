.store-panel-container {
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
}

.picture-container,
.picture-container img,
.marketplace-info-container {
  width: 100%;
}

.picture-container {
  margin-bottom: 30px;
}

.marketplace-info-container {
  font-family: 'Lato';
  margin: 10px 17px;
}

.marketplace-button {
  min-width: 100px;
  background: #1997c6;
  color: white;
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 2px;
}

.button,
.button-close {
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 10px;
  min-width: 100px;
}

.button {
  background-color: #1997c6;
  border: 1px #1997c6 solid !important;
  color: white;
}

.button-close {
  background-color: white;
  border: 1px #dedede solid;
}

.button:hover {
  opacity: 0.7;
  border: 1px #1686b0 solid !important;
}

@media screen and (min-width: 769px) {
  .store-panel-container {
    height: 350px;
    flex-wrap: nowrap;
  }

  .picture-container {
    margin-bottom: 0;
  }

  .picture-container img {
    height: 350px;
    width: auto;
  }

  .marketplace-info-container {
    margin: 0;
    padding: 0;
  }

  .marketplace-info-container img {
    max-width: 100%;
  }
}
