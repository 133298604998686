.button,
.buttonActive,
.customb-3utton-outline {
  font-family: 'Lato';
  width: 30%;
  font-size: 10px;
  letter-spacing: 2px;
  font-weight: 300;
  z-index: 0 !important;
}

.buttonActive,
.button:hover,
.customb-3utton-outline:hover {
  color: white !important;
}

.button {
  outline: none !important;
}

/* Select Dropdown for smaller viewports */
.select {
  width: 100%;
  border-radius: 0;
  border: 1px #56b8b8 solid;
}
