.customPanel {
  padding: 0px !important;
  border-radius: 0 !important;

  flex: 1 0 100%;
  flex-direction: column;
  align-content: stretch;
  height: 100%;

  border-radius: 0;
  border: 1px #ddd solid;
}

.notificationGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-card,
.custom-card-disabled {
  font-family: 'Lato';
  border-radius: 0;
}

.custom-card-disabled {
  opacity: 0.7;
}

@media screen and (min-width: 786px) {
  .custom-card:hover,
  .fitnessPanel:hover {
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 19px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 19px -1px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 19px -1px rgba(0, 0, 0, 0.4);
  }

  .custom-card:hover h3,
  .fitnessPanel:hover h3 {
    color: #56b8b8;
  }

  .custom-card:hover img {
    opacity: 0.7;
  }
}

.custom-card,
.custom-card-disabled {
  position: relative;
  border-radius: 0;
  width: 100%;
  border: 1px #ddd solid !important;
}
