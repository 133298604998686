.admin-container {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 20px;
}

.dashhead-header {
  display: flex;
  width: 100%;
}

.select-container {
  display: flex;
  margin-bottom: 10px;
}

.select-container div {
  margin-right: 10px;
}

.active {
  background: #1997c6 !important;
  color: white;
  border: none !important;
}

.grid {
  width: 100%;
}

.table {
  width: 100%;
}

.row td {
  width: 50%;
}
