.grid {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.header-container,
.comparison-header h1 {
  font-size: 30px;
  letter-spacing: 5px;
  font-family: 'Lato';
  margin-bottom: 30px;
}

.return-single {
  width: 100%;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 10px;
  margin-top: -15px;
}

.return-single u {
  color: #ff8a8a;
}

.return-single u:hover {
  cursor: pointer;
  color: #1997c6;
  opacity: 0.7;
}

.comparison-header {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0px;
}

@media print {
  .comparison-header {
    margin-top: 35px;
  }
}

.comparison-date-selector,
.date-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.comparison-date-selector h3,
.date-selector h3 {
  font-size: 1em;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  color: #ff8a8a;
}

.comparison-date-selector > *,
.date-selector > * {
  margin-right: 20px;
}

.comparison-label {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2px;
  margin-top: -15px;
  margin-bottom: 5px;
}

@media screen and (max-width: 602px) {
  .comparison-date-selector {
    flex-wrap: wrap;
  }

  .comparison-date-selector h3 {
    width: 100%;
  }

  .comparison-label {
    margin-top: 0;
  }
}

.img-container {
  border: 1px #ddd solid;
  margin-bottom: 30px;
}

.img-container img {
  width: 25%;
}

.comparison-images {
  display: flex;
  margin-top: 15px;
}

.comparison-img-container {
  border: 1px #ddd solid;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  text-align: center;
  padding: 15px 0;
}

.comparison-img-container:first-of-type {
  border-right: none;
}

.comparison-img-container:last-of-type {
  border-left: 1px #ddd dashed;
}

.comparison-img-container h5 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
  margin: 0 auto;
}

.comparison-img-container img {
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .comparison-header {
    margin-top: 20px;
  }

  .comparison-images {
    display: block;
  }

  .comparison-img-container {
    border: none;
    display: block;
  }

  .comparison-img-container h5 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .comparison-img-container:last-of-type {
    border-left: 1px #ddd dashed;
  }
}

.panel,
.metrics-panel {
  flex: 1 0 100%;
  border-radius: 0;
  border: 1px #ddd solid;
}

.metrics-panel {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  border: none;
  border-radius: 0;
}

.metrics-header-container {
  letter-spacing: 5px;
  font-family: 'Lato';
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

.metrics-header-container h1 {
  font-size: 26px;
  z-index: 0;
}

.bss {
  top: -10px;
  text-align: center;
  width: 100%;
  padding: 0;
  margin-bottom: 10px;
}

.bss h3 {
  font-size: 1em;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  color: #ff8a8a;
}

.name-cell {
  width: 70%;
}

.button-container {
  text-align: center;
}

.download {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.report-action-button {
  margin: 0 auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #ff8a8a;
  width: 180px;
  height: 40px;
  border: 0;
  font-family: 'Lato', sans-serif;
  letter-spacing: 2px;
  font-size: 0.8em;
  color: white;
  text-transform: uppercase;
}

.report-action-button:hover {
  opacity: 0.7;
}

@media print {
  .download {
    display: none;
  }

  .comparison-images {
    display: flex;
  }

  .comparison-img-container {
    display: block;
    border: none;
  }

  .comparison-img-container h5 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .comparison-img-container:last-of-type {
    border-left: 1px #ddd dashed;
  }

  .return-single {
    display: none;
  }
}

.hidden {
  display: none;
}
