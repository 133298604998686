.subnav-header {
  display: none;
  color: #657272;
  font-family: 'Lato', sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.25rem;
}

.subnav-subheader {
  color: #657272;
  font-size: 11px;
}

.custom-iconav-slider {
  border-bottom: 1px rgb(203, 216, 228) solid;
}

@media screen and (min-width: 768px) {
  .subnav-header {
    display: block;
    margin-bottom: 8px;
    padding-top: 24px;
  }

  .custom-iconav-slider {
    border-bottom: none;
  }
}
