.custom-iconav {
  width: 100%;
  height: 50px;
  display: flex;
  background: rgb(203, 216, 228);
  margin: 0;
  padding: 0 15px;
}

.custom-nav-pills {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  border-bottom: 1px rgb(203, 216, 228) solid;
}

.custom-nav-pills li {
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .sub-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 50px;
    width: 180px;
    margin-top: 0;
    margin-bottom: 0;
    background: #e5ebf1;
    overflow-y: auto;
  }

  .sub-nav-header {
    height: 112px;
  }

  .sub-nav .nav-item:hover,
  .custom-iconav .nav-item:hover {
    cursor: pointer;
  }

  .custom-iconav {
    width: 50px;
    height: auto;
    display: block;
    padding: 0;
  }

  .custom-nav-pills {
    border-bottom: none;
  }

  .custom-nav-pills li {
    margin: 0;
  }
}
