.marketplace-modal .modal-content {
  width: 650px;
}

.marketplace-modal-info {
  height: 465px;
  display: flex;
  align-items: center;
}

.marketplace-modal-title {
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 19px;
}

@media screen and (max-width: 649px) {
  .header-container h1 {
    font-size: 25px;
    text-align: center;
  }

  .marketplace-modal .modal-content {
    width: 100% !important;
  }
}
