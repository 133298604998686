.profile-container {
  position: absolute;
  height: 80%;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/* Action Tabs */
.profile-action-tab,
.profile-action-tab-selected {
  cursor: pointer;
  padding: 15px;
}

.profile-action-tab {
  border-bottom: 1px #dedede solid;
}

.profile-action-tab:first-of-type {
  border-top: 1px #dedede solid;
}

.profile-action-tab:hover {
  color: white;
  background: #dedede;
}

.profile-action-tab-selected {
  background: #1997c6;
  color: white;
}

/* Form */
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  border: 2px red solid;
}

.info {
  float: left;
}

.button-container {
  width: 100%;
  text-align: center;
}

.button,
.cancel-button,
.show-edit-button {
  width: 180px;
  border-radius: 0;
  height: 30px;
  font-family: 'Lato';
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
  font-weight: 300;
  margin: 20px 10px 30px 10px;
}

.cancel-button {
  background: white;
  border: 1px #aaa solid;
}

.cancel-button:hover {
  background: #56b8b8;
  border: 1px #56b8b8 solid;
  color: white;
}

.error {
  color: red;
}

.hidden {
  display: none;
}

.borderless {
  border: none !important;
}

@media screen and (max-width: 740px) and (max-height: 420px) {
  .profile-container {
    position: relative;
  }

  .button-container {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 420px) and (max-height: 740px) {
  .profile-container {
    position: relative;
  }

  .cancel-button {
    margin-top: -15px;
  }

  .button-container {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 768px) {
  .formr-3ow {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 480px) {
  .profile-card {
    border-left: 1px #dedede solid;
  }
}
