.wellness-history {
  width: 200px;
}

.wellness-history h1 {
  color: #484848;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
}
