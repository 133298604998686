.slider {
  -webkit-appearance: none;
  width: 100%;
  margin-right: 15px;
}

.slider:hover {
  cursor: pointer;
}

.sliderRange {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #ddd;
  border: none;
  border-radius: 10px;
  margin-top: -8px;
}

.control-button {
  margin-right: 10px;
}

.control-button,
.fullScreenButton {
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.control-button:hover,
.fullScreenButton:hover {
  cursor: pointer;
  color: #34a5a5;
}
