.image-container {
  padding: 0;
  width: 100%;
}

.image-container img {
  width: 100%;
  max-width: 180px;
}

.image-container div {
  width: 100%;
}

.download-scan-model {
  color: #1776c6;
}

.download-scan-model:hover {
  cursor: pointer;
  color: #ff8a8a;
}

.stat-button {
  display: flex;
  justify-content: center;
  width: 180px;
  margin-bottom: 5px;
}

.valid-button-group {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 75px;
  padding-top: 10px;
}

.valid-button-group button {
  margin: 0 5px;
  border-radius: 0;
  width: 70px;
  background: white;
  border: 1px #ddd solid;
  margin-bottom: 10px;
}

.valid-button-group button:hover {
  background: #1997c6;
  color: white;
}

.active {
  background: #1997c6 !important;
  color: white;
  border: none !important;
}

.info-container {
  width: 95%;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.add-metric {
  width: 100%;
  display: flex;
  text-align: right;
  margin: 0 auto;
  overflow: hidden;
  wrap: nowrap;
}

.add-metric select {
  width: 40%;
}

.circle-plus {
  margin-right: 5px;
}

.add:before {
  top: 0px !important;
}

.add {
  font-size: 14px;
  cursor: pointer;
  top: 0px !important;
  margin-left: 0.3em;
  background: none;
  border: none;
  padding: 0;
}

.add:hover {
  color: #ff8a8a;
}

.select {
  border: 1px #ddd solid;
  margin-right: 10px;
  margin-left: 10px;
}

.select {
  margin: 0 auto;
  width: 200px;
  border-radius: 2px;
}

.panel-container {
  margin-top: 20px;
}

.col {
  margin-bottom: 25px;
}

.col:nth-child(2n) {
  padding-right: 0;
}

.col:nth-child(2n + 1) {
  padding-left: 0;
}

.panel,
.panel-focus {
  width: 100%;
  position: relative;
  left: 0;
  border-radius: 0;
  border: 1px #ddd solid;
  font-family: 'Lato', sans-serif;
  display: flex;
  height: 80px;
  font-size: 17px;
}

.panel-focus {
  border: 1px #1997c6 solid;
}

.panel-title {
  padding: 10px;
  display: flex;
  align-items: center;
  width: 35%;
  border-right: 1px #ddd solid;
}

.panel-content {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
}

.panel-content input {
  width: 60px;
}

.edit-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cancel {
  font-size: 12px;
  color: red;
}

.cancel:hover {
  opacity: 0.7;
  cursor: pointer;
}

.edit-button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 15px;
  display: none;
}

.panel:hover .edit-button {
  display: block;
}

.edit-button:hover {
  color: #ff8a8a;
  cursor: pointer;
}

.good,
.bad {
  font-size: 14px;
  cursor: pointer;
}

.good:hover,
.bad:hover {
  opacity: 0.7;
}

.good {
  color: green;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 2px;
  right: 2px;
}

.error,
.bad {
  color: #d6003a;
}

.error {
  width: 100%;
}

.bad {
  position: absolute;
  top: 25px;
  right: 2px;
}

.hidden {
  display: none;
}

.return {
  margin-top: -10px;
  margin-bottom: 20px;
  float: right;
  cursor: pointer;
  color: #1997c6;
}

.return:hover {
  color: #ff8a8a;
}

.additional-form-header {
  border-bottom: 0;
  padding-bottom: 0;
}

.additional-list {
  margin-top: 10px;
  padding-left: 0;
}

.additional-button-group {
  display: flex;
  justify-content: flex-end;
}

.additional-button-group button {
  margin-left: 10px;
  width: 100px;
}

.notification-danger,
.notification-warning,
.notification-success {
  color: white;
  padding: 5px;
  font-weight: 300;
}

.notification-warning {
  background-color: #f4c20d;
}

.notification-danger {
  background-color: #f2695a;
}

.notification-success {
  background-color: #5cd985;
}
